import React, { useEffect, useRef, useState } from 'react';
import grater_logo from './images/grater_logo.png'
import './App.css';

function App() {

  interface ITypeableSentence {
    text: string;
    typeSpeed: number;
    delayStart: number;
    appendToPrevious?: true;
  };

  const [isLogoLoaded, setLogoIsLoaded] = useState(false);
  const isTypingRef = useRef(false);

  const waitForMs = (ms:number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  };

  useEffect(() => {

    if (!isLogoLoaded) {
      // Preload logo, to ensure it's there for the unbelievable spin-effect.
      const img = new Image()
      img.onload = function() {
        setLogoIsLoaded(true);
      }
      img.src = grater_logo;
    }

    if (!isTypingRef.current && isLogoLoaded) {
      isTypingRef.current = true;

      const type = async (sentences:Array<ITypeableSentence>, el:HTMLParagraphElement) => {
        for (const sentence of sentences) {
          await waitForMs(sentence.delayStart);
          const words = sentence.text.split(" ");
          let i = 0;
          while (i < words.length) {
            let s = null;
            if (sentence.appendToPrevious) {
              const spans = container.getElementsByTagName('span');
              s = spans[spans.length-2];
            } else {
              s = document.createElement('span');
              el.insertBefore(s, cursor);
            }
            const letters = words[i].split("");
            for (const letter of letters) {
              if (sentence.typeSpeed > 0) {
                  await waitForMs((sentence.typeSpeed/5) + (1 + Math.floor(Math.random() * (sentence.typeSpeed*5))));
                  if (s) s.append(letter);
              } else {
                el.append(sentence.text);
              }
            }
            i++;
            if (i < words.length) {
              if (s) s.append(" ");
            }
          }
        }
      };
      const container = document.getElementById("teaserText") as HTMLParagraphElement;
      const startSpan = document.getElementById("teaserTextStart") as HTMLParagraphElement;
      const cursor = document.getElementById("cursor") as HTMLParagraphElement;
      container.addEventListener("transitionend", () => {
        startSpan.style.display = "block";
        cursor.style.display = "inline";
      });
      const sentences:Array<ITypeableSentence> = [
        {text:'Experienced freelance software engineer',typeSpeed:20,delayStart:1500},
        {text:', ',typeSpeed:30,delayStart:500, appendToPrevious: true},
        {text:'at your service.',typeSpeed:30,delayStart:0},
      ];
      setTimeout(() => {
        container.style.minHeight = "4rem";
        type(sentences, container);
      },2500);
    }
  },[isLogoLoaded]);

  return (
    <>
      {isLogoLoaded && <div className="content">
        <img src={grater_logo} className="graterLogo" alt="Grater Consulting logo" />
        <p><a href="mailto:tero@grater.dev">tero@grater.dev</a></p>
        <p id="teaserText" className="teaserTextParagraph">
          <span id="teaserTextStart">&gt;&gt; </span>
          <span id="cursor"> </span>
        </p>
      </div> }
      {isLogoLoaded && <footer>
        <hr/>
        <p>
          Grater Consulting Oy | 3354320-4 | Vantaa, Finland
        </p>
      </footer> }
    </>
  );
}

export default App;
